// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { defaults } from './defaults';

export const environment = {
    production: false,
    name: 'dev',
    frontendAPIRoot: 'https://dev.frontend.swap.bike/api/',
    apiRoot: 'https://dev.frontend.swap.bike/api/',
    authentication: {
        identityServer: 'https://dev.identity.swap.bike',
        clientId: '8b46675b-6465-4201-8dab-811e4aac446e',
    },
    giftCard: {
        apiRoot: 'https://dev.frontend.swap.bike/api/',
    },
    googleTagManager: {
        enabled: true,
        id: 'GTM-WWLN9HK',
        auth: 'ddR0QPC1m9vLPKv7512dDw',
        preview: 'env-5',
    },
    facebookPixel: {
        id: '137265040282187',
    },
    sharedCookies: {
        host: 'https://staging.swapfiets.nl',
        path: '/swookies.html',
    },
    cookieInfo: {
        ...defaults.cookieInfo,
    },
    countries: [
        {
            ...defaults.countries.NL,
        },
        {
            ...defaults.countries.BE,
        },
        {
            ...defaults.countries.DE,
        },
        {
            ...defaults.countries.DK,
        },
        {
            ...defaults.countries.FR,
        },
        {
            ...defaults.countries.IT,
        },
        {
            ...defaults.countries.GB,
        },
        {
            ...defaults.countries.ES,
        },
        {
            ...defaults.countries.AT,
        },
    ],
};
